/* prueba.css */

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    backdrop-filter: blur(15px); /* Ajusta el valor de desenfoque según tus preferencias */
    z-index: -1;
  }
  @media screen and (min-width: 200px){
    .blur-background {
    
      height: 145vh;
     
    }
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
    .blur-background {
    
      height: 100vh;
     
    }
  }
  @media screen and (min-width: 1200px){

  }


  @media screen and (min-width: 838px) and (orientation: landscape) {
 

}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}
  /* Resto de tus estilos... */
  